
import { Component, Vue } from 'vue-property-decorator';

import InputDefault from '@/components/form/InputMain.vue';
import InputPassword from '@/components/form/InputPassword.vue';
import BtnSubmit from '@/components/form/BtnSubmit.vue';

import authModule from '@/admin/store/auth';
import SiteModule from '@/admin/store/site';

interface Styles {
    root: string;
    userMenu: string;
    userName: string;
    isHidden: string;
}

@Component({
    components: { InputDefault, BtnSubmit, InputPassword },
})
export default class LoginUser extends Vue {
    $style!: Styles;

    get isBlock() {
        return SiteModule.isBlock;
    }

    get fields() {
        return authModule.model;
    }

    get text() {
        return authModule.text;
    }

    setLoginValue(value: string) {
        authModule.setLoginValue(value);
    }

    setPasswordValue(value: string) {
        authModule.setPasswordValue(value);
    }

    async auth() {
        if (this.isBlock) {
            return;
        }
        SiteModule.setIsBlock(true);

        await authModule.loginRequest();
        this.$router.push({ name: 'home' });
        SiteModule.setIsBlock(false);
    }
}
