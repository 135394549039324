<template>
    <button type="submit" :class="classes" @click="action">
        <loading v-if="isLoading === true"></loading>
        <span v-else>
            <slot></slot>
        </span>
    </button>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';

export default {
    components: {
        Loading,
    },
    props: {
        classAdd: {
            type: String,
            required: false,
            default: '',
        },
        handler: {
            type: String,
            required: false,
            default: '',
        },
    },
    methods: {
        action() {
            this.$emit('click');
        },
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.site.isBlock,
        }),
        classes() {
            const classIsLoading = this.isLoading === true ? 'loading' : '';

            return `${classIsLoading} ${this.classAdd}`;
        },
    },
};
</script>
